export const cityJson = {
  reason: "success!",
  result: [
    {
      province_id: "1",
      province: "安徽",
      citys: [
        { city_id: "10001", city: "合肥" },
        { city_id: "10002", city: "芜湖" },
        { city_id: "10003", city: "蚌埠" },
        { city_id: "10004", city: "淮南" },
        { city_id: "10005", city: "马鞍山" },
        { city_id: "10006", city: "淮北" },
        { city_id: "10007", city: "铜陵" },
        { city_id: "10008", city: "安庆" },
        { city_id: "10009", city: "黄山" },
        { city_id: "10010", city: "滁州" },
        { city_id: "10011", city: "阜阳" },
        { city_id: "10012", city: "宿州" },
        { city_id: "10013", city: "六安" },
        { city_id: "10014", city: "亳州" },
        { city_id: "10015", city: "池州" },
        { city_id: "10016", city: "宣城" },
      ],
    },
    {
      province_id: "2",
      province: "北京",
      citys: [{ city_id: "10017", city: "北京" }],
    },
    {
      province_id: "3",
      province: "重庆",
      citys: [{ city_id: "10018", city: "重庆" }],
    },
    {
      province_id: "4",
      province: "福建",
      citys: [
        { city_id: "10019", city: "福州" },
        { city_id: "10020", city: "厦门" },
        { city_id: "10021", city: "莆田" },
        { city_id: "10022", city: "三明" },
        { city_id: "10023", city: "泉州" },
        { city_id: "10024", city: "漳州" },
        { city_id: "10025", city: "南平" },
        { city_id: "10026", city: "龙岩" },
        { city_id: "10027", city: "宁德" },
      ],
    },
    {
      province_id: "5",
      province: "广东",
      citys: [
        { city_id: "10028", city: "广州" },
        { city_id: "10029", city: "韶关" },
        { city_id: "10030", city: "深圳" },
        { city_id: "10031", city: "珠海" },
        { city_id: "10032", city: "汕头" },
        { city_id: "10033", city: "佛山" },
        { city_id: "10034", city: "江门" },
        { city_id: "10035", city: "湛江" },
        { city_id: "10036", city: "茂名" },
        { city_id: "10037", city: "肇庆" },
        { city_id: "10038", city: "惠州" },
        { city_id: "10039", city: "梅州" },
        { city_id: "10040", city: "汕尾" },
        { city_id: "10041", city: "河源" },
        { city_id: "10042", city: "阳江" },
        { city_id: "10043", city: "清远" },
        { city_id: "10044", city: "东莞" },
        { city_id: "10045", city: "中山" },
        { city_id: "10046", city: "潮州" },
        { city_id: "10047", city: "揭阳" },
        { city_id: "10048", city: "云浮" },
      ],
    },
    {
      province_id: "6",
      province: "甘肃",
      citys: [
        { city_id: "10049", city: "兰州" },
        { city_id: "10050", city: "嘉峪关" },
        { city_id: "10051", city: "金昌" },
        { city_id: "10052", city: "白银" },
        { city_id: "10053", city: "天水" },
        { city_id: "10054", city: "武威" },
        { city_id: "10055", city: "张掖" },
        { city_id: "10056", city: "平凉" },
        { city_id: "10057", city: "酒泉" },
        { city_id: "10058", city: "庆阳" },
        { city_id: "10059", city: "定西" },
        { city_id: "10060", city: "陇南" },
        { city_id: "10061", city: "临夏" },
        { city_id: "10062", city: "甘南" },
      ],
    },
    {
      province_id: "7",
      province: "广西",
      citys: [
        { city_id: "10063", city: "南宁" },
        { city_id: "10064", city: "柳州" },
        { city_id: "10065", city: "桂林" },
        { city_id: "10066", city: "梧州" },
        { city_id: "10067", city: "北海" },
        { city_id: "10068", city: "防城港" },
        { city_id: "10069", city: "钦州" },
        { city_id: "10070", city: "贵港" },
        { city_id: "10071", city: "玉林" },
        { city_id: "10072", city: "百色" },
        { city_id: "10073", city: "贺州" },
        { city_id: "10074", city: "河池" },
        { city_id: "10075", city: "来宾" },
        { city_id: "10076", city: "崇左" },
      ],
    },
    {
      province_id: "8",
      province: "贵州",
      citys: [
        { city_id: "10077", city: "贵阳" },
        { city_id: "10078", city: "六盘水" },
        { city_id: "10079", city: "遵义" },
        { city_id: "10080", city: "安顺" },
        { city_id: "10081", city: "毕节" },
        { city_id: "10082", city: "铜仁" },
        { city_id: "10083", city: "黔西南" },
        { city_id: "10084", city: "黔东南" },
        { city_id: "10085", city: "黔南" },
      ],
    },
    {
      province_id: "9",
      province: "河北",
      citys: [
        { city_id: "10086", city: "石家庄" },
        { city_id: "10087", city: "唐山" },
        { city_id: "10088", city: "秦皇岛" },
        { city_id: "10089", city: "邯郸" },
        { city_id: "10090", city: "邢台" },
        { city_id: "10091", city: "保定" },
        { city_id: "10092", city: "张家口" },
        { city_id: "10093", city: "承德" },
        { city_id: "10094", city: "沧州" },
        { city_id: "10095", city: "廊坊" },
        { city_id: "10096", city: "衡水" },
      ],
    },
    {
      province_id: "10",
      province: "湖北",
      citys: [
        { city_id: "10097", city: "武汉" },
        { city_id: "10098", city: "黄石" },
        { city_id: "10099", city: "十堰" },
        { city_id: "10100", city: "宜昌" },
        { city_id: "10101", city: "襄阳" },
        { city_id: "10102", city: "鄂州" },
        { city_id: "10103", city: "荆门" },
        { city_id: "10104", city: "孝感" },
        { city_id: "10105", city: "荆州" },
        { city_id: "10106", city: "黄冈" },
        { city_id: "10107", city: "咸宁" },
        { city_id: "10108", city: "随州" },
        { city_id: "10109", city: "恩施" },
        { city_id: "10110", city: "仙桃" },
        { city_id: "10111", city: "潜江" },
        { city_id: "10112", city: "天门" },
        { city_id: "10113", city: "神农架" },
      ],
    },
    {
      province_id: "11",
      province: "黑龙江",
      citys: [
        { city_id: "10114", city: "哈尔滨" },
        { city_id: "10115", city: "齐齐哈尔" },
        { city_id: "10116", city: "鸡西" },
        { city_id: "10117", city: "鹤岗" },
        { city_id: "10118", city: "双鸭山" },
        { city_id: "10119", city: "大庆" },
        { city_id: "10120", city: "伊春" },
        { city_id: "10121", city: "佳木斯" },
        { city_id: "10122", city: "七台河" },
        { city_id: "10123", city: "牡丹江" },
        { city_id: "10124", city: "黑河" },
        { city_id: "10125", city: "绥化" },
        { city_id: "10126", city: "大兴安岭" },
      ],
    },
    {
      province_id: "12",
      province: "海南",
      citys: [
        { city_id: "10127", city: "海口" },
        { city_id: "10128", city: "三亚" },
        { city_id: "10129", city: "三沙" },
        { city_id: "10130", city: "儋州" },
        { city_id: "10131", city: "五指山" },
        { city_id: "10132", city: "琼海" },
        { city_id: "10133", city: "文昌" },
        { city_id: "10134", city: "万宁" },
        { city_id: "10135", city: "东方" },
        { city_id: "10136", city: "定安" },
        { city_id: "10137", city: "屯昌" },
        { city_id: "10138", city: "澄迈" },
        { city_id: "10139", city: "临高" },
        { city_id: "10140", city: "白沙" },
        { city_id: "10141", city: "昌江" },
        { city_id: "10142", city: "乐东" },
        { city_id: "10143", city: "陵水" },
        { city_id: "10144", city: "保亭" },
        { city_id: "10145", city: "琼中" },
      ],
    },
    {
      province_id: "13",
      province: "河南",
      citys: [
        { city_id: "10146", city: "郑州" },
        { city_id: "10147", city: "开封" },
        { city_id: "10148", city: "洛阳" },
        { city_id: "10149", city: "平顶山" },
        { city_id: "10150", city: "安阳" },
        { city_id: "10151", city: "鹤壁" },
        { city_id: "10152", city: "新乡" },
        { city_id: "10153", city: "焦作" },
        { city_id: "10154", city: "濮阳" },
        { city_id: "10155", city: "许昌" },
        { city_id: "10156", city: "漯河" },
        { city_id: "10157", city: "三门峡" },
        { city_id: "10158", city: "南阳" },
        { city_id: "10159", city: "商丘" },
        { city_id: "10160", city: "信阳" },
        { city_id: "10161", city: "周口" },
        { city_id: "10162", city: "驻马店" },
        { city_id: "10163", city: "济源" },
      ],
    },
    {
      province_id: "14",
      province: "湖南",
      citys: [
        { city_id: "10164", city: "长沙" },
        { city_id: "10165", city: "株洲" },
        { city_id: "10166", city: "湘潭" },
        { city_id: "10167", city: "衡阳" },
        { city_id: "10168", city: "邵阳" },
        { city_id: "10169", city: "岳阳" },
        { city_id: "10170", city: "常德" },
        { city_id: "10171", city: "张家界" },
        { city_id: "10172", city: "益阳" },
        { city_id: "10173", city: "郴州" },
        { city_id: "10174", city: "永州" },
        { city_id: "10175", city: "怀化" },
        { city_id: "10176", city: "娄底" },
        { city_id: "10177", city: "湘西" },
      ],
    },
    {
      province_id: "15",
      province: "吉林",
      citys: [
        { city_id: "10178", city: "长春" },
        { city_id: "10179", city: "吉林" },
        { city_id: "10180", city: "四平" },
        { city_id: "10181", city: "辽源" },
        { city_id: "10182", city: "通化" },
        { city_id: "10183", city: "白山" },
        { city_id: "10184", city: "松原" },
        { city_id: "10185", city: "白城" },
        { city_id: "10186", city: "延边" },
      ],
    },
    {
      province_id: "16",
      province: "江苏",
      citys: [
        { city_id: "10187", city: "南京" },
        { city_id: "10188", city: "无锡" },
        { city_id: "10189", city: "徐州" },
        { city_id: "10190", city: "常州" },
        { city_id: "10191", city: "苏州" },
        { city_id: "10192", city: "南通" },
        { city_id: "10193", city: "连云港" },
        { city_id: "10194", city: "淮安" },
        { city_id: "10195", city: "盐城" },
        { city_id: "10196", city: "扬州" },
        { city_id: "10197", city: "镇江" },
        { city_id: "10198", city: "泰州" },
        { city_id: "10199", city: "宿迁" },
      ],
    },
    {
      province_id: "17",
      province: "江西",
      citys: [
        { city_id: "10200", city: "南昌" },
        { city_id: "10201", city: "景德镇" },
        { city_id: "10202", city: "萍乡" },
        { city_id: "10203", city: "九江" },
        { city_id: "10204", city: "新余" },
        { city_id: "10205", city: "鹰潭" },
        { city_id: "10206", city: "赣州" },
        { city_id: "10207", city: "吉安" },
        { city_id: "10208", city: "宜春" },
        { city_id: "10209", city: "抚州" },
        { city_id: "10210", city: "上饶" },
      ],
    },
    {
      province_id: "18",
      province: "辽宁",
      citys: [
        { city_id: "10211", city: "沈阳" },
        { city_id: "10212", city: "大连" },
        { city_id: "10213", city: "鞍山" },
        { city_id: "10214", city: "抚顺" },
        { city_id: "10215", city: "本溪" },
        { city_id: "10216", city: "丹东" },
        { city_id: "10217", city: "锦州" },
        { city_id: "10218", city: "营口" },
        { city_id: "10219", city: "阜新" },
        { city_id: "10220", city: "辽阳" },
        { city_id: "10221", city: "盘锦" },
        { city_id: "10222", city: "铁岭" },
        { city_id: "10223", city: "朝阳" },
        { city_id: "10224", city: "葫芦岛" },
      ],
    },
    {
      province_id: "19",
      province: "内蒙古",
      citys: [
        { city_id: "10225", city: "呼和浩特" },
        { city_id: "10226", city: "包头" },
        { city_id: "10227", city: "乌海" },
        { city_id: "10228", city: "赤峰" },
        { city_id: "10229", city: "通辽" },
        { city_id: "10230", city: "鄂尔多斯" },
        { city_id: "10231", city: "呼伦贝尔" },
        { city_id: "10232", city: "巴彦淖尔" },
        { city_id: "10233", city: "乌兰察布" },
        { city_id: "10234", city: "兴安" },
        { city_id: "10235", city: "锡林郭勒" },
        { city_id: "10236", city: "阿拉善" },
      ],
    },
    {
      province_id: "20",
      province: "宁夏",
      citys: [
        { city_id: "10237", city: "银川" },
        { city_id: "10238", city: "石嘴山" },
        { city_id: "10239", city: "吴忠" },
        { city_id: "10240", city: "固原" },
        { city_id: "10241", city: "中卫" },
      ],
    },
    {
      province_id: "21",
      province: "青海",
      citys: [
        { city_id: "10242", city: "西宁" },
        { city_id: "10243", city: "海东" },
        { city_id: "10244", city: "海北" },
        { city_id: "10245", city: "黄南" },
        { city_id: "10246", city: "海南" },
        { city_id: "10247", city: "果洛" },
        { city_id: "10248", city: "玉树" },
        { city_id: "10249", city: "海西" },
      ],
    },
    {
      province_id: "22",
      province: "四川",
      citys: [
        { city_id: "10250", city: "成都" },
        { city_id: "10251", city: "自贡" },
        { city_id: "10252", city: "攀枝花" },
        { city_id: "10253", city: "泸州" },
        { city_id: "10254", city: "德阳" },
        { city_id: "10255", city: "绵阳" },
        { city_id: "10256", city: "广元" },
        { city_id: "10257", city: "遂宁" },
        { city_id: "10258", city: "内江" },
        { city_id: "10259", city: "乐山" },
        { city_id: "10260", city: "南充" },
        { city_id: "10261", city: "眉山" },
        { city_id: "10262", city: "宜宾" },
        { city_id: "10263", city: "广安" },
        { city_id: "10264", city: "达州" },
        { city_id: "10265", city: "雅安" },
        { city_id: "10266", city: "巴中" },
        { city_id: "10267", city: "资阳" },
        { city_id: "10268", city: "阿坝" },
        { city_id: "10269", city: "甘孜" },
        { city_id: "10270", city: "凉山" },
      ],
    },
    {
      province_id: "23",
      province: "山东",
      citys: [
        { city_id: "10271", city: "济南" },
        { city_id: "10272", city: "青岛" },
        { city_id: "10273", city: "淄博" },
        { city_id: "10274", city: "枣庄" },
        { city_id: "10275", city: "东营" },
        { city_id: "10276", city: "烟台" },
        { city_id: "10277", city: "潍坊" },
        { city_id: "10278", city: "济宁" },
        { city_id: "10279", city: "泰安" },
        { city_id: "10280", city: "威海" },
        { city_id: "10281", city: "日照" },
        { city_id: "10282", city: "临沂" },
        { city_id: "10283", city: "德州" },
        { city_id: "10284", city: "聊城" },
        { city_id: "10285", city: "滨州" },
        { city_id: "10286", city: "菏泽" },
      ],
    },
    {
      province_id: "24",
      province: "上海",
      citys: [{ city_id: "10287", city: "上海" }],
    },
    {
      province_id: "25",
      province: "山西",
      citys: [
        { city_id: "10288", city: "太原" },
        { city_id: "10289", city: "大同" },
        { city_id: "10290", city: "阳泉" },
        { city_id: "10291", city: "长治" },
        { city_id: "10292", city: "晋城" },
        { city_id: "10293", city: "朔州" },
        { city_id: "10294", city: "晋中" },
        { city_id: "10295", city: "运城" },
        { city_id: "10296", city: "忻州" },
        { city_id: "10297", city: "临汾" },
        { city_id: "10298", city: "吕梁" },
      ],
    },
    {
      province_id: "26",
      province: "陕西",
      citys: [
        { city_id: "10299", city: "西安" },
        { city_id: "10300", city: "铜川" },
        { city_id: "10301", city: "宝鸡" },
        { city_id: "10302", city: "咸阳" },
        { city_id: "10303", city: "渭南" },
        { city_id: "10304", city: "延安" },
        { city_id: "10305", city: "汉中" },
        { city_id: "10306", city: "榆林" },
        { city_id: "10307", city: "安康" },
        { city_id: "10308", city: "商洛" },
      ],
    },
    {
      province_id: "27",
      province: "天津",
      citys: [{ city_id: "10309", city: "天津" }],
    },
    {
      province_id: "28",
      province: "新疆",
      citys: [
        { city_id: "10310", city: "乌鲁木齐" },
        { city_id: "10311", city: "克拉玛依" },
        { city_id: "10312", city: "吐鲁番" },
        { city_id: "10313", city: "哈密" },
        { city_id: "10314", city: "昌吉" },
        { city_id: "10315", city: "博尔塔拉" },
        { city_id: "10316", city: "巴音郭楞" },
        { city_id: "10317", city: "阿克苏" },
        { city_id: "10318", city: "克孜勒苏" },
        { city_id: "10319", city: "喀什" },
        { city_id: "10320", city: "和田" },
        { city_id: "10321", city: "伊犁" },
        { city_id: "10322", city: "塔城" },
        { city_id: "10323", city: "阿勒泰" },
      ],
    },
    {
      province_id: "29",
      province: "西藏",
      citys: [
        { city_id: "10324", city: "拉萨" },
        { city_id: "10325", city: "日喀则" },
        { city_id: "10326", city: "昌都" },
        { city_id: "10327", city: "林芝" },
        { city_id: "10328", city: "山南" },
        { city_id: "10329", city: "那曲" },
        { city_id: "10330", city: "阿里" },
      ],
    },
    {
      province_id: "30",
      province: "云南",
      citys: [
        { city_id: "10331", city: "昆明" },
        { city_id: "10332", city: "曲靖" },
        { city_id: "10333", city: "玉溪" },
        { city_id: "10334", city: "保山" },
        { city_id: "10335", city: "昭通" },
        { city_id: "10336", city: "丽江" },
        { city_id: "10337", city: "普洱" },
        { city_id: "10338", city: "临沧" },
        { city_id: "10339", city: "楚雄" },
        { city_id: "10340", city: "红河" },
        { city_id: "10341", city: "文山" },
        { city_id: "10342", city: "西双版纳" },
        { city_id: "10343", city: "大理" },
        { city_id: "10344", city: "德宏" },
        { city_id: "10345", city: "怒江" },
        { city_id: "10346", city: "迪庆" },
      ],
    },
    {
      province_id: "31",
      province: "浙江",
      citys: [
        { city_id: "10347", city: "杭州" },
        { city_id: "10348", city: "宁波" },
        { city_id: "10349", city: "温州" },
        { city_id: "10350", city: "嘉兴" },
        { city_id: "10351", city: "湖州" },
        { city_id: "10352", city: "绍兴" },
        { city_id: "10353", city: "金华" },
        { city_id: "10354", city: "衢州" },
        { city_id: "10355", city: "舟山" },
        { city_id: "10356", city: "台州" },
        { city_id: "10357", city: "丽水" },
      ],
    },
    {
      province_id: "32",
      province: "台湾",
      citys: [{ city_id: "10358", city: "台湾" }],
    },
    {
      province_id: "33",
      province: "香港",
      citys: [{ city_id: "10359", city: "香港" }],
    },
    {
      province_id: "34",
      province: "澳门",
      citys: [{ city_id: "10360", city: "澳门" }],
    },
  ],
  error_code: 0,
};
